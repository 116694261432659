import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField, withStyles, FormControl, FormHelperText } from '@material-ui/core';
import '../../style/mainStyle.css';

const styles = theme => ({
    errorMsg: {
        fontFamily: "Raleway",
        fontWeight: 400,
        position: 'absolute',
        top: 10,
        right: 10,
        whiteSpace: 'nowrap',
        overflow: 'ellipsis',
        color: theme.palette.primary.Error,
        fontSize: 15
    },
    formControl: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
    },
    textField: {
        borderRadius: 20
    }
});

function TextFields(props) {
    const { classes, onChange, type, maxLength, name, placeholder, inputProps, rows, disabled, value, id, helper, IsValid, multiline, children } = props;
    return (
        <Fragment>
            <FormControl className={classes.formControl} aria-describedby="name-helper-text">
                <TextField
                    id={id}
                    disabled={disabled != undefined}
                    className="textField"
                    value={value}
                    inputProps={inputProps}
                    type={type}
                    name={name}
                    label={value == '' && IsValid == true ? id : id}
                    rows={rows}
                    onChange={onChange}
                    onInput={(e) => {
                        if (type == 'tel' && e.target.value.length != 0) {
                            if (!isNaN(e.target.value)) {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength == undefined ? 14 : maxLength)
                            }
                            else {
                                e.target.value = ''
                            }
                        }
                        else {
                            e.target.value = e.target.value
                        }
                    }}
                    placeholder={placeholder}
                    multiline={multiline != undefined && true}
                    error={value == '' && IsValid == true ? true : false}
                    variant="outlined"
                    {...props}
                    inputProps={{ maxLength: maxLength != undefined ? maxLength : 50 }}
                >
                    {children}
                </TextField>

                {helper && value == '' && IsValid == true && <FormHelperText className={classes.errorMsg} id="name-helper-text">{helper}</FormHelperText>}
            </FormControl>
        </Fragment>
    );
}

TextFields.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFields);