import React, { Component } from 'react';
import { Route, BrowserRouter } from "react-router-dom";
import logo from './logo.svg';
import Dashboard from './containers/Dashboard'
import PrivacyPolicy from './containers/PrivacyPolicy/PrivacyPolicy'
import './style/mainStyle.css';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Route exact path="/" name="Dashboard" component={Dashboard} />
                <Route exact path="/PrivacyPolicy" name="PrivacyPolicy" component={PrivacyPolicy} />
                {/* <Route exact path="/Signup" name="Signup" component={Signup} /> */}
            </BrowserRouter>
        );
    }
}

export default App;
