import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import CommonButton from "../Button/CommonButton";

function CommonModal(props) {
    const { Title, ActionText, onClose, maxWidth, IsLoading, onSave, IsScroll, children, IsDeletePopup } = props;
    const handleClose = () => {
        onClose(false)
    };
    return (
        <div >
            <Dialog
                open={true}
                maxWidth={maxWidth}
                onClose={props.isOuterClick == undefined ? handleClose : props.isOuterClick}
                scroll={IsScroll != undefined ? 'body' : 'paper'}
                aria-labelledby="scroll-dialog-title">
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <DialogTitle style={{ marginBottom: '0px' }} id="scroll-dialog-title">{Title}</DialogTitle>
                    <span style={{ margin: '10px', padding: '10px', cursor: 'pointer', fontSize: '17px' }} onClick={handleClose}>✘</span>
                </div>
                <DialogContent style={{ overflow: 'hidden', }} >
                    <DialogContentText>
                        {IsDeletePopup && <Typography color='textPrimary' style={{ fontSize: 20, fontWeight: 'bold' }}>Are you sure you want to {Title}?</Typography>}
                        {children}
                    </DialogContentText>
                </DialogContent>
                {IsDeletePopup &&
                    <DialogActions>
                        {/* <CommonButton IsLoading={IsLoading} onClick={onSave} color="primary">
                            {ActionText}
                        </CommonButton> */}
                        <CommonButton onClick={handleClose} color="secondary">
                            {'Close'}
                        </CommonButton>
                    </DialogActions>}
            </Dialog>

        </div>
    );

}

export default CommonModal;