const Location = window.location.toString();
const Geturl = () => {
    if (Location.indexOf('chemisto.live') != -1) {
        return 'https://api.chemisto.live/'
    } else if (Location.indexOf('kmisto.com') != -1) {
        return 'https://api.chemisto.live/'
    } else {
        return 'https://chemisto.api.thestaging.cc/'
    }
}

export const URLs = {
    base: Geturl(),

    // Auth
    Gettoken: 'token',
    GetCityForDropdown: 'API/Master/GetCityForDropdown',
    GetCompanyForDropdown: 'API/Master/GetCompanyForDropdown',
    StoreSignup: 'API/Store/StoreSignup',
}