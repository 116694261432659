import React, { Component } from 'react';

import Checked from '../../assets/checked.png';

class SuccessPopup extends Component {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <img src={Checked} type='file' />
                <div style={{ marginTop: '20px' }}>
                    <label>Hi <strong>{this.props.States.FirstName} {this.props.States.LastName}</strong> Your Store <strong>{this.props.States.StoreName}</strong> Registered Successfully.</label><br></br>
                    <label>We will soon send you your login details.<strong style={{ fontSize: '20px', fontWeight: 600 }}>☺</strong></label>
                </div>
            </div>
        );
    }
}

export default SuccessPopup