

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles, CircularProgress } from '@material-ui/core';
// import CancelIcon from '@material-ui/icons/Cancel';
// import Save from '@material-ui/icons/Save';

const styles = theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

function CommonButton(props) {
    const { classes, name, color, id, ButtonText, IsLoading, onSubmit, onClick, children, type } = props;
    return (
        <Fragment>
            <div className={classes.wrapper}>
                <Button
                    type={type != undefined && 'submit'} name={name} id={id}
                    variant="contained"
                    color={color != undefined ? color : 'primary'}
                    onClick={onClick}
                    className={classes.buttonSuccess}
                    disabled={IsLoading} >
                    {ButtonText}
                    {/* {children === 'Close' ? <CancelIcon style={{ fontSize: 20, marginRight: 5 }} /> : <Save style={{ fontSize: 20, marginRight: 5 }} />} */}
                    {children}
                </Button>
                {IsLoading && IsLoading != undefined && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </Fragment>
    );
}

CommonButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CommonButton);