import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import uuid from 'react-uuid';

import addimage from '../../assets/addimage.png';

import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";
import CommonButton from "../../components/Button/CommonButton";

import CommonModal from "../../components/Modal/CommonModal";
import SuccessPopup from "../Register/SuccessPopup";

import * as APIController from '../../APIs/APIController.js';

import '../../components/Upload/react-dropzone/react-dropzone.css';

class Register extends Component {
    state = {
        StoreName: '',
        Address1: '',
        CityID: '',
        Postcode: '',
        FirstName: '',
        LastName: '',
        MobileNo: '',
        PhoneNo: '',
        EmailAddress: '',
        DrugLicence: '',
        GSTNo: '',
        PanNo: '',
        Renewal: '',
        CityOption: [],
        IsValid: false,
        DrugLicenceImageName: '',
        DrugLicenceImageData: '',
        DrugLicenceImagePreview: '',
        GSTNoImageName: '',
        GSTNoImageData: '',
        GSTNoImagePreview: '',
        PanNoImageName: '',
        PanNoImageData: '',
        PanNoImagePreview: '',
        Modalopen: false,
        progress: false,
    }

    componentDidMount() {
        this.wsGetCityForDropdown()
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    wsGetCityForDropdown() {
        APIController.GetCityForDropdown().then((response) => {
            if (response.object.data.length) {
                this.setState({ CityOption: response.object.data })
            }
            else if (response.error.message) {
            }
        });
    }

    handleValidate() {
        if (this.state.StoreName == '') {
            this.setState({ IsValid: true })
        } else if (this.state.FirstName == '') {
            this.setState({ IsValid: true })
        } else if (this.state.LastName == '') {
            this.setState({ IsValid: true })
        } else if (this.state.Address1 == '') {
            this.setState({ IsValid: true })
        } else if (this.state.CityID == '') {
            this.setState({ IsValid: true })
        } else if (this.state.Postcode == '' || this.state.Postcode.length < 6) {
            this.setState({ IsValid: true })
            alert("Please Enter Valid Postcode")
        } else if (this.state.EmailAddress == '' || !RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.EmailAddress.toLocaleLowerCase())) {
            this.setState({ IsValid: true })
            alert("Please Enter Valid Email")
        } else if (this.state.MobileNo == '' || this.state.MobileNo.length < 10) {
            this.setState({ IsValid: true })
            alert("Please Enter Valid Mobile No")
        }
        //  else if (this.state.DrugLicence == '') {
        //     this.setState({ IsValid: true })
        // }
        // else if (this.state.GSTNo != '' && this.state.GSTNo.length < 15) {
        //     this.setState({ IsValid: true })
        //     alert("Please Enter Valid GST No")
        // } else if (this.state.PanNo != '' && this.state.PanNo.length != 10) {
        //     this.setState({ IsValid: true })
        //     alert("Please Enter Valid PAN No")
        // }
        //  else if (this.state.DrugLicenceImageData == '') {
        //     alert("Please Upload Drug Licence Photo")
        // }
        else {
            let TempArray = [
                {
                    "DocumentTypeID": "bce33835-f7c9-45c6-a08f-d9a0857f3432",
                    "DocumentNo": this.state.DrugLicence,
                    "DocumentExpiryDate": this.state.Renewal,
                    "DocumentName": this.state.DrugLicenceImageName,
                    "DocumentData": this.state.DrugLicenceImageData,
                    "DocumentURL": ""
                }, {
                    "DocumentTypeID": "64d8cac4-4c48-4beb-989d-f9d59ba8f1e9",
                    "DocumentNo": this.state.GSTNo,
                    "DocumentExpiryDate": "",
                    "DocumentName": this.state.GSTNoImageName,
                    "DocumentData": this.state.GSTNoImageData,
                    "DocumentURL": ""
                }, {
                    "DocumentTypeID": "954fcf9d-5d04-47f8-9ce0-0412009b29a4",
                    "DocumentNo": this.state.PanNo,
                    "DocumentExpiryDate": "",
                    "DocumentName": this.state.PanNoImageName,
                    "DocumentData": this.state.PanNoImageData,
                    "DocumentURL": ""
                },
            ]

            this.wsStoreSignup(
                this.state.StoreName,
                this.state.Address1,
                this.state.Postcode,
                this.state.CityID,
                this.state.EmailAddress,
                this.state.MobileNo,
                this.state.MobileNo1,
                this.state.FirstName,
                this.state.LastName,
                TempArray
            )
        }
    }

    wsStoreSignup(StoreName, Address1, Postcode, CityID, EmailAddress, MobileNo, MobileNo1, FirstName, LastName, Documents) {
        this.setState({ progress: true, });
        APIController.StoreSignup(StoreName, Address1, Postcode, CityID, EmailAddress, MobileNo, MobileNo1, FirstName, LastName, Documents)
            .then((response) => {
                this.setState({ progress: false });
                if (response.object.status == 1) {
                    this.setState({ Modalopen: true })
                }
            });
    }

    render() {
        const { StoreName, Address1, CityID, Postcode, FirstName, LastName, MobileNo, PhoneNo, EmailAddress, DrugLicence, GSTNo, PanNo,
            CityOption, IsValid } = this.state;
        return (
            <>
                <form onSubmit={(e) => { e.preventDefault(); this.handleValidate(); }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: 1000 }} className='MainGrid'>
                        <div>
                            <Grid container>
                                <Grid container xs={12} sm={12} md={6} xl={6}>
                                    <TextField name={'StoreName'} id={'Store Name'} onChange={this.handleChange} value={StoreName} maxLength={100} IsValid={IsValid} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={3} xl={3}>
                                    <TextField name={'FirstName'} id={'Contact First Name'} onChange={this.handleChange} value={FirstName} maxLength={50} IsValid={IsValid} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={3} xl={3}>
                                    <TextField name={'LastName'} id={'Contact Last Name'} onChange={this.handleChange} value={LastName} maxLength={50} IsValid={IsValid} />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid container xs={12} sm={12} md={6} xl={6}>
                                    <TextField name={'Address1'} id={'Address'} onChange={this.handleChange} value={Address1} maxLength={200} IsValid={IsValid} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={3} xl={3}>
                                    <DropDown name={'CityID'} value={CityID} id={'Select City'} onChange={this.handleChange} optionText={CityOption} IsValid={IsValid} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={3} xl={3}>
                                    <TextField type='tel' maxLength={6} name={'Postcode'} id={'Post Code'} onChange={this.handleChange} value={Postcode} IsValid={IsValid} />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid container xs={12} sm={12} md={6} xl={6}>
                                    <TextField name={'EmailAddress'} id={'Email Address'} onChange={this.handleChange} value={EmailAddress} maxLength={50} IsValid={IsValid} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={3} xl={3}>
                                    <TextField type='tel' maxLength={10} name={'MobileNo'} id={'Mobile No'} onChange={this.handleChange} value={MobileNo} IsValid={IsValid} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={3} xl={3}>
                                    <TextField type='tel' maxLength={11} name={'PhoneNo'} id={'Phone No'} onChange={this.handleChange} value={PhoneNo} />
                                </Grid>
                            </Grid>
                        </div>

                        <Grid container direction='row' className='MainGrid' style={{ marginTop: '10px' }}>

                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <Grid container xs={12} sm={12} md={12} xl={12}>
                                    <TextField name={'DrugLicence'} id={'Drug Licence No'} onChange={this.handleChange} value={DrugLicence} maxLength={50} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} xl={12}>
                                    <div className="NotesAttachments">
                                        {this.state.DrugLicenceImagePreview == "" ?
                                            <div>
                                                <label style={{ zIndex: "1000" }}>
                                                    <input type='file' name='files' style={{ display: 'none' }} id='files' accept=".svg,.png,.jpeg,.jpg"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file &&
                                                                (file.type.includes("svg") ||
                                                                    file.type.includes("png") ||
                                                                    file.type.includes("jpeg") ||
                                                                    file.type.includes("jpg"))) {
                                                                let file1 = e.target.files[0]
                                                                file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                                this.setState({ DrugLicenceImageName: uuid() + "." + file.type.split('/')[1] })

                                                                const image2base64 = require('image-to-base64');
                                                                image2base64(file1.preview)
                                                                    .then((response) => {
                                                                        this.setState({ DrugLicenceImageData: response })
                                                                    })
                                                                    .catch((error) => { })

                                                                this.setState({ DrugLicenceImagePreview: file1.preview })
                                                            }
                                                        }} />
                                                    <img src={addimage} type='file' style={{ cursor: 'pointer' }} />
                                                </label>
                                            </div>
                                            :
                                            <div>
                                                <span onClick={() => { this.setState({ DrugLicenceImagePreview: '' }) }}>✘</span>
                                                <a className="tip">
                                                    <img title={this.state.DrugLicenceImagePreview} style={{ zIndex: "1000" }} src={this.state.DrugLicenceImagePreview}></img>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <Grid container xs={12} sm={12} md={12} xl={12}>
                                    <TextField name={'GSTNo'} id={'GST No'} onChange={this.handleChange} value={GSTNo} maxLength={15} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} xl={12}>
                                    <div className="NotesAttachments">
                                        {this.state.GSTNoImagePreview == "" ?
                                            <div>
                                                <label style={{ zIndex: "1000" }}>
                                                    <input type='file' name='files' style={{ display: 'none' }} id='files' accept=".svg,.png,.jpeg,.jpg"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file &&
                                                                (file.type.includes("svg") ||
                                                                    file.type.includes("png") ||
                                                                    file.type.includes("jpeg") ||
                                                                    file.type.includes("jpg"))) {
                                                                let file1 = e.target.files[0]
                                                                file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                                this.setState({ GSTNoImageName: uuid() + "." + file.type.split('/')[1] })

                                                                const image2base64 = require('image-to-base64');
                                                                image2base64(file1.preview)
                                                                    .then((response) => {
                                                                        this.setState({ GSTNoImageData: response })
                                                                    })
                                                                    .catch((error) => { })

                                                                this.setState({ GSTNoImagePreview: file1.preview })
                                                            }
                                                        }} />
                                                    <img src={addimage} type='file' style={{ cursor: 'pointer' }} />
                                                </label>
                                            </div>
                                            :
                                            <div>
                                                <span onClick={() => { this.setState({ GSTNoImagePreview: '' }) }}>✘</span>
                                                <a className="tip">
                                                    <img title={this.state.GSTNoImagePreview} style={{ zIndex: "1000" }} src={this.state.GSTNoImagePreview}></img>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} xl={4}>
                                <Grid container xs={12} sm={12} md={12} xl={12}>
                                    <TextField name={'PanNo'} id={'PAN No'} onChange={this.handleChange} value={PanNo} maxLength={10} />
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} xl={12}>
                                    <div className="NotesAttachments">
                                        {this.state.PanNoImagePreview == "" ?
                                            <div>
                                                <label style={{ zIndex: "1000" }}>
                                                    <input type='file' name='files' style={{ display: 'none' }} id='files' accept=".svg,.png,.jpeg,.jpg"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file &&
                                                                (file.type.includes("svg") ||
                                                                    file.type.includes("png") ||
                                                                    file.type.includes("jpeg") ||
                                                                    file.type.includes("jpg"))) {
                                                                let file1 = e.target.files[0]
                                                                file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                                this.setState({ PanNoImageName: uuid() + "." + file.type.split('/')[1] })

                                                                const image2base64 = require('image-to-base64');
                                                                image2base64(file1.preview)
                                                                    .then((response) => {
                                                                        this.setState({ PanNoImageData: response })
                                                                    })
                                                                    .catch((error) => { })

                                                                this.setState({ PanNoImagePreview: file1.preview })
                                                            }
                                                        }} />
                                                    <img src={addimage} type='file' style={{ cursor: 'pointer' }} />
                                                </label>
                                            </div>
                                            :
                                            <div>
                                                <span onClick={() => { this.setState({ PanNoImagePreview: '' }) }}>✘</span>
                                                <a className="tip">
                                                    <img title={this.state.PanNoImagePreview} style={{ zIndex: "1000" }} src={this.state.PanNoImagePreview}></img>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid container style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '20px' }}>
                            <CommonButton IsLoading={this.state.progress} type='submit' color="primary"> {'Register Me'}</CommonButton>
                        </Grid>
                    </Grid>
                </form>

                {this.state.Modalopen &&
                    <CommonModal
                        // maxWidth={'md'}
                        Title={"Congratulations"}
                        ActionText={"Congratulations"}
                        onClose={this.handleClose.bind(this)}
                        IsScroll >

                        <SuccessPopup onClose={this.handleClose.bind(this)} States={this.state} />
                    </CommonModal>
                }
            </>
        );
    }

    handleClose() {
        this.setState({ Modalopen: false })
        this.props.onClose();
    }
}

export default Register