// import * as APICaller from '../APIs/APICaller';
import { API, HTTPMethod } from "../APIs/API";
import { URLs } from "../APIs/URLs";

export function APIControllerError(code, message) {
    this.code = code
    this.message = message
}

export function APIControllerResponse(object, error) {
    this.object = object
    this.error = error
}

export function GetAccessToken(username, password) {
    // let data = JSON.parse(values)
    var details = {
        username: username,
        password: password,
        DeviceID: '1111111111-1111111111',
        DeviceTypeID: '1',
        grant_type: "password",
    };
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let url = URLs.base + URLs.Gettoken;
    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, formBody)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(response.StatusCode, response.Message)
                    resolve(new APIControllerResponse(null, error))
                } else if (response && response.access_token) {
                    resolve(new APIControllerResponse(response, null))
                    // localStorage.setItem("Access_Token", response.access_token);
                } else if (response.error_description != '') {
                    // ToastsStore.warning(response.error_description)
                    let error = new APIControllerError(response)
                    resolve(new APIControllerResponse(null, error))
                }
            })
            .catch((error) => {
                resolve(new APIControllerResponse(null, error))
            });
    });
}

export function GetCityForDropdown() {
    let params = JSON.stringify({});
    let url = URLs.base + URLs.GetCityForDropdown;
    return new Promise((resolve, reject) => {
        API.shared
            .request(HTTPMethod.post, url, params)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(
                        response.StatusCode,
                        response.Message
                    );
                    resolve(new APIControllerResponse(null, error));
                } else if (response && response.data) {
                    resolve(new APIControllerResponse(response, null));
                }
            })
            .catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}
export function GetCompanyForDropdown() {
    let params = JSON.stringify({});
    let url = URLs.base + URLs.GetCompanyForDropdown;
    return new Promise((resolve, reject) => {
        API.shared
            .request(HTTPMethod.post, url, params)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(
                        response.StatusCode,
                        response.Message
                    );
                    resolve(new APIControllerResponse(null, error));
                } else if (response && response.data) {
                    resolve(new APIControllerResponse(response, null));
                }
            })
            .catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}
export function StoreSignup(StoreName, Address1, Postcode, CityID, EmailAddress, MobileNo, MobileNo1, FirstName, LastName, Documents) {
    let params = JSON.stringify({
        StoreName: StoreName,
        Address1: Address1,
        Address2: '',
        Postcode: Postcode,
        CityID: CityID,
        EmailAddress: EmailAddress,
        MobileNo: MobileNo,
        MobileNo1: MobileNo1,
        FirstName: FirstName,
        LastName: LastName,
        Documents: Documents
    });
    console.log('params:', params)
    let url = URLs.base + URLs.StoreSignup;
    return new Promise((resolve, reject) => {
        API.shared
            .request(HTTPMethod.post, url, params)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(
                        response.StatusCode,
                        response.Message
                    );
                    resolve(new APIControllerResponse(null, error));
                } else if (response && response.data) {
                    resolve(new APIControllerResponse(response, null));
                }
            })
            .catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}