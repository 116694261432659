import React, { Component } from 'react';
import { TagCloud } from 'react-tagcloud'
import * as APIController from '../APIs/APIController.js';
import CircularProgress from '@material-ui/core/CircularProgress';

const customRenderer = (tag, size, color) => (
    <span key={tag.value}
        style={{
            animation: 'blinker 3s linear infinite',
            animationDelay: `${Math.random() * 2}s`,
            fontSize: `${size / 3.2}em`,
            border: `2px solid ${color}`,
            borderRadius: '10px',
            margin: '5px',
            padding: '5px',
            display: 'inline-block',
            color: '#00104C',
        }} > {tag.value} </span>
)

class CompanyList extends Component {
    state = {
        CompanyOption: [],
        progress: false,
    }

    componentWillMount() {
        this.wsGetCompanyForDropdown()
    }

    wsGetCompanyForDropdown() {
        this.setState({ progress: true });
        APIController.GetCompanyForDropdown().then((response) => {
            if (response.object.data.length) {
                const CompanyData = response.object.data.map(function (item) {
                    return { value: item.Name, count: 10 }
                });
                this.setState({ CompanyOption: CompanyData, progress: false })
            } else {
                this.setState({ progress: false })
            }
        });
    }

    render() {
        const { progress, CompanyOption } = this.state;
        return (
            <div>
                {progress
                    ? <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </div>
                    : !CompanyOption.length
                        ? <div style={{ textAlign: 'center' }}>404 company not found ! ☹</div>
                        : <TagCloud tags={CompanyOption} minSize={1} maxSize={5} renderer={customRenderer} />}
            </div>
        );
    }
}

export default CompanyList