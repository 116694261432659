import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import privacyPolicyStyle from './PrivacyPolicyStyle';
import { CssBaseline, Grid, Button, } from '@material-ui/core';
// import Footer from '../../components/Header/Footer';
// import MedkartHeader from "../../components/Header/MedkartHeader";
// import Title from "../../components/Header/Title";
// import ReactGA from 'react-ga';
// import Helmet from "react-helmet";
class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            loading: true,
        }
    }
    componentDidMount() {
        // ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <div style={{ background: '#fffF', color: '#000', textAlign: 'start' }}>
                {/* <Title title={'Privacy Policy'} isBack={false} history={this.props.history} /> */}
                <Grid container style={{ width: '100%', }} direction="row" justify="space-evenly" alignItems="center">
                    <Grid style={mediumBrowser ? { width: '100%' } : { width: '98.5%' }} container frameBorder='0'>
                        <Grid style={{ marginRight: 3, marginLeft: 3, marginTop: '13px', fontFamily: 'Nunito Sans' }}>

                            <p><strong>Privacy Policy</strong></p>
                            <p>Please read this privacy policy carefully by accessing or using the website, you agree to be bound by the terms described herein and all the terms incorporated by reference. If you do not agree to all of these terms, do not use the website.</p>
                            <ol>
                                <li><strong> Content &amp; Purpose</strong></li>
                            </ol>
                            <ul>
                                <li>This privacy policy (&ldquo;Privacy Policy&rdquo;) applies to your use of the domain name www.Kmisto.live, an internet based portal, and Kmisto.live a mobile application, owned and operated by Pharma Wall Private Limited, a company duly incorporated under the provisions of the Companies Act, 2013 (hereinafter, referred to as &ldquo;Kmisto&rdquo; or &ldquo;Kmisto&rdquo; or &ldquo;We&rdquo; or &ldquo;Our&rdquo; or &ldquo;Us&rdquo; or &ldquo;Company&rdquo;). The domain name and the mobile application are collectively referred to as &ldquo;Website&rdquo;.</li>
                                <li>The Website is a platform that facilitates the purchase of pharmaceutical products for sale by various relevant pharmacies (hereinafter referred to as &ldquo;Services&rdquo;, with the relevant pharmacies referred to as &ldquo;Sellers&rdquo;). The arrangement between the Sellers and Kmisto shall be governed in accordance with this Privacy Policy and the Terms of Use. The Services would be made available to such natural persons who have agreed to become buyers on the Website after obtaining due registration, in accordance with the procedure as determined by Kmisto, from time to time (referred to as &ldquo;You&rdquo; or &ldquo;Your&rdquo; or &ldquo;Yourself&rdquo; or &ldquo;User&rdquo;, which terms shall also include natural persons who are accessing the Website merely as visitors). The Services are offered to the Users through various modes which shall include issue of discount coupons and vouchers that can be redeemed for various goods/ services offered for sale by the Sellers.</li>
                                <li>We have implemented reasonable security practices and procedures that are commensurate with the information assets being protected and with the nature of our business. While we try our best to provide security that is commensurate with the industry standards, because of the inherent vulnerabilities of the internet, we cannot ensure or warrant complete security of all information that is being transmitted to us by you.</li>
                                <li>For the purpose of providing the Services and for other purposes identified in this Privacy Policy, Kmisto will be required to collect and host certain data and information of the Users. Kmisto is committed to protecting the Personal Information of the Users and takes all reasonable precautions for maintaining confidentiality of the User&rsquo;s Personal Information. This Privacy Policy has been designed and developed to help you understand the following:
                                    <ul>
                                        <li>The type of Personal Information (including Sensitive Personal Data or Information) that Kmisto collects from the Users;</li>
                                        <li>The purpose of collection, means and modes of usage of such Personal Information by Kmisto;</li>
                                        <li>How and to whom Kmisto will disclose such information;</li>
                                        <li>How Kmisto will protect the Personal Information including Sensitive Personal Data or Information that is collected from the Users; and</li>
                                        <li>How Users may access and/ or modify their Personal Information.</li>
                                    </ul>
                                </li>
                                <li>This Privacy Policy shall apply to the use of the Website by all Users / Sellers. Accordingly, a condition of each User's use of and access to the Website and to the other services provided by Kmisto to Users is their acceptance of this Privacy policy. Any User is required to read and understand the provisions set out herein prior to submitting any Sensitive Personal Data or Information to Kmisto, failing which they are required to leave the Website immediately.</li>
                                <li>This Privacy Policy is published in compliance of the (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.</li>
                            </ul>
                            <ol start="2">
                                <li><strong> What is the personal information?</strong></li>
                            </ol>
                            <ul>
                                <li>"Personal Information" means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available with Kmisto, is capable of identifying the person concerned.</li>
                                <li>"Sensitive Personal Data or Information" means Personal Information of any individual relating to password; financial information such as bank account or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any detail relating to the above as provided to or received by Kmisto for processing or storage. However, any data/ information relating to an individual that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law shall not qualify as Sensitive Personal Data or Information.</li>
                            </ul>
                            <ol start="3">
                                <li><strong> Types of personal information collected by Kmisto:</strong></li>
                            </ol>
                            <ul>
                                <li>A User may have limited access to the Website and utilize some of the Services provided by Kmisto without creating an account on the Website. Unregistered Users can access some of the information and details available on the Website. In order to have access to all the features and benefits on our Website, a User may be required to first create an account on our Website. As part of the registration process, Kmisto may collect the following categories of Personal Information from the Users (hereinafter collectively referred to as &ldquo;User Information&rdquo;):
                                    <ul>
                                        <li>Name;</li>
                                        <li>User ID;</li>
                                        <li>Email address;</li>
                                        <li>Address (including country and ZIP/postal code);</li>
                                        <li>Drugs license number</li>
                                        <li>S.T. Number</li>
                                        <li>Mobile number</li>
                                        <li>Password chosen by the user.</li>
                                    </ul>
                                </li>
                                <li>Kmisto may keep records of telephone calls received and made for making inquiries, orders, or other purposes for the purpose of administration of Services.</li>
                                <li>Internet use: Kmisto may also receive and/or hold information about the User&rsquo;s browsing history including the URL of the site that the User visited prior to visiting the website as well as the Internet Protocol (IP) address of each User's computer/Mobile (or the proxy server a User used to access the World Wide Web), User's computer operating system and type of web browser the User is using as well as the name of User's ISP. The Website uses temporary cookies to store certain data (that is not Sensitive Personal Data or Information) that is used by Kmisto and its service providers for the technical administration of the Website, research and development, and for User administration.</li>
                                <li>The Website/App may enable User to communicate with other Users or to post information to be accessed by others, whereupon other Users may collect such data. Kmisto hereby expressly disclaims any liability for any misuse of such information that is made available by visitors in such a manner.</li>
                                <li>Kmisto does not knowingly collect Personal Information from children.</li>
                                <li>Kmisto may in future include other optional requests for information from the User including through user surveys in order to help Kmisto customize the Website to deliver personalized information to the User and for other purposes are mentioned herein. Such information may also be collected in the course of contests conducted by Kmisto. Any such additional Personal Information will also be processed in accordance with this Privacy Policy.</li>
                            </ul>
                            <ol start="4">
                                <li><strong> Purposes for which your information may be used by Kmisto:</strong></li>
                            </ol>
                            <ul>
                                <li>Kmisto will retain User Information only to the extent it is necessary to provide Services to the Users. The information which Kmisto collects from you may be utilized for various business and/or regulatory purposes including for the following purposes:
                                    <ul>
                                        <li>Registration of the User on the Website/App;</li>
                                        <li>Processing the User&rsquo;s orders / requests and provision of Services (including provision of safe Services);</li>
                                        <li>Completing transactions with Users effectively and billing for the products/ Services provided;</li>
                                        <li>Technical administration and customization of Website/App;</li>
                                        <li>Ensuring that the Website/App content is presented to the Users in an effective manner;</li>
                                        <li>Delivery of personalized information and target advertisements to the User;</li>
                                        <li>Improvement of Services, features and functionality of the Website/App;</li>
                                        <li>Research and development and for User administration (including conducting user surveys);</li>
                                        <li>Non-personally identifiable information, exclusively owned by Kmisto may be used in an aggregated or non-personally identifiable form for internal research, statistical analysis and business intelligence purposes including those for the purposes of determining the number of visitors and transactional details, and Kmisto may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates;</li>
                                        <li>Dealing with requests, enquiries, complaints or disputes and other customer care related activities including those arising out of the Users&rsquo; request of the Services and all other general administrative and business purposes;</li>
                                        <li>In case of any contests conducted by Kmisto in which the User participates, the User Information may be used for prize fulfillment and other aspects of any contest or similar offering;</li>
                                        <li>Communicate any changes in our Services or this Privacy Policy or the Terms of Use to the Users;</li>
                                        <li>Verification of identity of Users and perform checks to prevent frauds; and</li>
                                        <li>Investigating, enforcing, resolving disputes and applying our Terms of Use and Privacy Policy, either ourselves or through third party service providers.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ol start="5">
                                <li><strong> Disclosure and transfer of your personal information:</strong></li>
                            </ol>
                            <ul>
                                <li>Kmisto may need to disclose/ transfer User&rsquo;s Personal Information to the following third parties for the purposes mentioned in this Privacy Policy and the Terms of Use:
                                    <ul>
                                        <li>To Sellers and other service providers appointed by Kmisto for the purpose of carrying out services on Kmisto&rsquo;s behalf under contract. Generally these contractors do not have any independent right to share this information, however certain contractors who provide services on the Website, including the providers of online communications services, will have rights to use and disclose the Personal Information collected in connection with the provision of these services in accordance with their own privacy policies.</li>
                                        <li>To our affiliates in India or in other countries who may use and disclose your information for the same purposes as us.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>To government institutions/ authorities to the extent required a) under the laws, rules, and regulations and/ or under orders of any relevant judicial or quasi-judicial authority; b) to protect and defend the rights or property of Kmisto; c) to fight fraud and credit risk; d) to enforce Kmisto 's Terms of Use (to which this Privacy Policy is also a part) ; or e) when Kmisto, in its sole discretion, deems it necessary in order to protect its rights or the rights of others.</p>
                            <ul>
                                <li>If otherwise required by an order under any law for the time being in force including in response to enquiries by Government agencies for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offences.</li>
                                <li>In case of contests conducted by Kmisto in which the User participates, the concerned User&rsquo;s information may be disclosed to third parties, also be disclosed to third parties to the extent necessary for prize fulfillment and other aspects of such contest or similar offering.</li>
                            </ul>
                            <ul>
                                <li>Kmisto makes all User Information accessible to its employees and data processors only on a need-to-know basis. All Kmisto employees and data processors, who have access to, and are associated with the processing of User Information, are obliged to respect its confidentiality.</li>
                                <li>Non-personally identifiable information may be disclosed to third party ad servers, ad agencies, technology vendors and research firms to serve advertisements to the Users. Kmisto may also share its aggregate findings (not specific information) based on information relating to the User&rsquo;s internet use to prospective, investors, strategic partners, sponsors and others in order to help growth of Kmisto 's business.</li>
                                <li>Kmisto may also disclose or transfer the User Information, to another third party as part of reorganization or a sale of the assets or business of a Kmisto corporation division or company. Any third party to which Kmisto transfers or sells its assets will have the right to continue to use the Personal Information and/ or other information that a User provide to us.</li>
                            </ul>
                            <ol start="6">
                                <li><strong> Retention of the information:</strong></li>
                            </ol>
                            <ul>
                                <li>All the information collected/ stored under this Privacy Policy and Terms of Use is maintained by Kmisto in electronic form on its equipment, and on the equipment of its employees. User Information may also be converted to physical form from time to time. Regardless of the manner of storage, Kmisto will keep all User Information confidential and will use/ disclose it only the manner specified under the Privacy Policy and Terms of Use.</li>
                                <li>Part of the functionality of the Website is assisting the Sellers to maintain and organize such information to effect sale and purchase of products. Kmisto may, therefore, retain and submit all such records to the appropriate authorities, or to Sellers who request access to such information.</li>
                                <li>The Website is also designed for assisting the Users to access information relating to pharmaceutical products. Kmisto may, therefore, retain and submit all such records to the relevant Users.</li>
                                <li>Kmisto will also ensure that User Information is not kept for a period longer than is required for the purposes for which it is collected or as required under any applicable law.</li>
                            </ul>
                            <ol start="7">
                                <li><strong> Links to third-party advertisements:</strong></li>
                            </ol>
                            <ul>
                                <li>The links to third-party advertisements, third party websites or any third party electronic communication services (referred to as &ldquo;Third Party Links&rdquo;) may be provided on the Website which are operated by third parties and are not controlled by, or affiliated to, or associated with Kmisto unless expressly specified on the Website.</li>
                                <li>If you access any such Third Party Links, we request you review the website&rsquo;s privacy policy. We are not responsible for the policies or practices of Third Party Links.</li>
                            </ul>
                            <ol start="8">
                                <li><strong> Security practices and procedures:</strong></li>
                            </ol>
                            <ul>
                                <li>Kmisto adopts reasonable security practices and procedures to include, technical, operational, managerial and physical security control measures in order to protect the Personal Information in its possession from loss, misuse and unauthorized access, disclosure, alteration and destruction.</li>
                                <li>Kmisto takes adequate steps to ensure that third parties to whom the Users&rsquo; Sensitive Personal Data or Information may be transferred adopt reasonable level of security practices and procedures to ensure security of Personal Information.</li>
                                <li>You hereby acknowledge that Kmisto is not responsible for any intercepted information sent via the internet, and you hereby release us from any and all claims arising out of or related to the use of intercepted information in any unauthorized manner.</li>
                            </ul>
                            <ol start="9">
                                <li><strong> User's rights in relation to their personal information collected by Kmisto:</strong></li>
                            </ol>
                            <ul>
                                <li>All the information provided to Kmisto by a User, including Sensitive Personal Data or Information, is voluntary. User has the right to withdraw his/ her/ its consent at any time, in accordance with the terms of this Privacy Policy and the Terms of Use, but please note that withdrawal of consent will not be retroactive.</li>
                                <li>Users can access, modify, correct and delete the Personal Information provided by them which has been voluntarily given by the User and collected by Kmisto in accordance with this Privacy Policy and Terms of Use. However, if the User updates his/ her information, Kmisto may keep a copy of the information which User originally provided to Kmisto in its archives for User documented herein. In case the User seeks to update or correct, his/ her Personal Information, the User may exercise these rights by emailing Kmisto at admin@Kmisto.live and communicate the change(s) for updating Kmisto&rsquo;s records.</li>
                                <li>If a User, as a casual visitor, has inadvertently browsed any other pages of this Website prior to reading the Privacy Policy and the Terms of Use, and such User does not agree with the manner in which such information is obtained, stored or used, merely quitting this browser application should ordinarily clear all temporary cookies installed by Kmisto. All visitors, however, are encouraged to use the "clear cookies" functionality of their browsers to ensure such clearing/ deletion, as Kmisto cannot guarantee, predict or provide for the behavior of the equipment of all the visitors of the Website.</li>
                                <li>If a User has inadvertently submitted any Personal Information to Kmisto prior to reading the Privacy Policy and Terms of Use, and such User does not agree with the manner in which such information is collected, stored or used, then such User can ask Kmisto, by sending an email to admin@Kmisto.live containing the rectification required, whether Kmisto is keeping Personal Information about such User, and every User is also entitled to require Kmisto to delete and destroy all such information relating to such user (but not other Users) in its possession.</li>
                                <li>In case the User does not provide his/ her information or consent for usage of Personal Information or subsequently withdraws his/ her consent for usage of the Personal Information so collected, Kmisto reserves the right to discontinue the services for which the said information was sought.</li>
                            </ul>
                            <ol start="10">
                                <li><strong> Additional notes to the user:</strong></li>
                            </ol>
                            <ul>
                                <li>Kmisto does not exercise control over the sites displayed as search results or links from within its Services. These other sites may place their own cookies or other files on the Users' computer, collect data or solicit Personal Information from the Users, for which Kmisto is not responsible or liable. Accordingly, Kmisto does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor does Kmisto guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites. Kmisto encourages the User to read the privacy policies of that website.</li>
                                <li>Kmisto shall not be responsible in any manner for the authenticity of the Personal Information or Sensitive Personal Data or Information supplied by the User to Kmisto or any Seller. If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Kmisto has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Kmisto has the right to suspend or terminate such account at its sole discretion.</li>
                                <li>Kmisto shall not be responsible for any breach of security or for any actions of any third parties that receive Users' Personal Information or events that are beyond the reasonable control of Kmisto including, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, etc.</li>
                                <li>The User is responsible for maintaining the confidentiality of the User's account access information and password. The User shall be responsible for all uses of the User's account and password, whether or not authorized by the User. The User shall immediately notify Kmisto of any actual or suspected unauthorized use of the User's account or password.</li>
                                <li>Kmisto will communicate with the Users through email and notices posted on the Website or through other means available through the Service, including text and other forms of messaging. The Users can ask Kmisto, by sending an email to admin@Kmisto.live containing the rectification required.</li>
                            </ul>
                            <ol start="11">
                                <li><strong> Changes in the privacy policy:</strong></li>
                            </ol>
                            <ul>
                                <li>Kmisto may update this Privacy Policy at any time, with or without advance notice. In the event there are significant changes in the way Kmisto treats User's Personal Information, Kmisto will display a notice on the Website or send Users an email. If a User uses the Service after notice of changes have been sent to such User or published on the Website, such User hereby provides his/ her/ its consent to the changed practices.</li>
                            </ul>
                            <ol start="12">
                                <li><strong> Complaints and grievance redressal:</strong></li>
                            </ol>
                            <ul>
                                <li>Kmisto addresses discrepancies and grievances of all Users with respect to processing of information in a time bound manner. but within one month from the date of receipt of grievance, and who can be reached by:
                                    <ul>
                                        <li>Sending a letter marked to the attention of &ldquo;Pharmawall Pvt Ltd.Plot No.-1942/A,Shop 1, Nand Plaza,Sardarnagar Bhavnagar-364001&rdquo;, or</li>
                                        <li>Sending an email to admin@Kmisto.live</li>
                                    </ul>
                                </li>
                            </ul>
                            <p><strong>Contact us</strong></p>
                            <p>You may contact us with any questions relating to this Privacy Policy by submitting your request to admin@Kmisto.live or by postal mail at: Registered Address: Pharmawall Pvt Ltd. Plot no.-1942/A shop-1, Nand plaza Sardarnagar, Bhavnagar-364001.</p>
                            <p>&nbsp;</p>

                        </Grid>
                    </Grid>
                </Grid>
                {/* {window.innerWidth <= 920 ? <Grid/>:<BottomMenu history={this.props.history} />} */}
            </div>
        );
    }
}
export default PrivacyPolicy;
