import React from 'react';

import CommonModal from "../components/Modal/CommonModal";
import Register from "./Register/Register";
import CompanyList from "./CompanyList";
import image from "../assets/logo.png"
import googleplay from "../assets/google-play.png"

class Dashboard extends React.Component {

    state = { Modalopen: false, isRegister: false }

    componentDidMount() {
        var url = window.location.href
        var data = url.split('?')
        if (data[1] != undefined && data[1] == 'App') {
            this.setState({ Modalopen: true, isRegister: true })
        }
    }
    handleClose() {
        this.setState({ Modalopen: false, isRegister: false })
    }

    render() {
        return (
            <div>
                <div class="site-wrapper">
                    <div class="site-wrapper-inner">
                        <div class="cover-container" >
                            <div class="masthead clearfix">
                                <div class="inner">
                                    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                                        <div style={{ display: "inherit" }}>
                                            <img type='file' title={"image"} src={image} style={{ height: "50px" }} />
                                            <h3 class="masthead-brand">&nbsp;Kmisto</h3>
                                        </div>
                                        {/* <div style={{ display: "inherit" }}>
                                                <h3 class="masthead-brand" style={{ cursor: 'pointer' }} onClick={() => this.setState({ Modalopen: true, isRegister: false })} >Companys</h3> */}
                                        <div>
                                            <h3 class="masthead-brand" style={{ cursor: 'pointer' }} onClick={() => this.setState({ Modalopen: true, isRegister: true })} >Register Now&nbsp;&nbsp;</h3>
                                            <h3 class="masthead-brand" style={{ cursor: 'pointer' }} onClick={() => window.open('https://portal.chemisto.live/')} >|&nbsp;&nbsp;Login</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="inner cover">
                                <h1 class="cover-heading">The adventure Begins</h1>
                                <p class="lead cover-copy">Hold tight as we get our working robots together and produce the most astonishing product ever.</p>
                            </div>

                            <div class="mastfoot">
                                <div class="inner">
                                    <span class="lead cover-company">Well Equipped with Hundreds of the <strong style={{ cursor: 'pointer' }} className='blink' onClick={() => this.setState({ Modalopen: true, isRegister: false })} ><span>Companies.</span></strong></span>
                                    <img type='file' title={"Download Kmisto App"} src={googleplay} style={{ height: "40px", cursor: 'pointer ' }} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.vmobee.reactnative.chemisto')} />
                                </div>
                                <div class="inner" style={{ padding: '0 0 30px' }}>
                                    <span style={{ fontSize: '15px' }} class="lead cover-company">For Technical Queries Call +91 98982 71413 or E-mail <a href='mailto:admin@chemisto.live'>admin@Kmisto.live</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.Modalopen &&
                    <CommonModal
                        maxWidth={'md'}
                        Title={this.state.isRegister ? "Registration" : "Companies we Cater"}
                        ActionText={"Registration"}
                        onClose={this.handleClose.bind(this)}
                        IsScroll >

                        {this.state.isRegister ? <Register onClose={this.handleClose.bind(this)} States={this.state} />
                            : <CompanyList onClose={this.handleClose.bind(this)} States={this.state} />}
                    </CommonModal>
                }
            </div>
        );
    }
}
export default Dashboard;